import React , {useEffect} from 'react'

const Privacy = () => {

    
    

    return (
        <>

            <section className='bg-gray-50 flex  px-3 py-3 md:p-8 font-[roboto]' >
                <div className='w-full  md:w-[85%] bg-white p-4 rounded-md border-2 border-gray-100 drop-shadow-md shadow-lg mx-auto'>

                    <div className='my-3'>
                        <h3 className='text-gray-900 font-bold uppercase text-center font-[poppins] text-[14px] sm:text-[16px] md:text-[22px]'>privacy policy</h3>
                    </div>

                    <div className='mt-8 flex flex-col  gap-y-4 md:gap-y-6 px-1 md:px-5'>

                        <div className='flex flex-col gap-y-3 '>
                            {/* <p className='font-bold text-[14px] sm:text-[16px] md:text-[20px] text-gray-600'>Introduction</p> */}
                            <p className='text-gray-600 text-[11px] sm:text-[12px] md:text-[14px] '>We take personal information about you when you enter a request, sign up to our information sheet or register your interest as a potential volunteer. The data we gather consist of your name, address, e-mail as well as phone numbers. This personal information is not distributed, shared, rented or given to companies or organizations that are not part of the Kenmai Foundation.
                            </p>
                        </div>

                        <div className='flex flex-col gap-y-3 '>
                            {/* <p className='font-bold text-[14px] sm:text-[16px] md:text-[20px] text-gray-600'>1. Information We Collect</p> */}
                            <p className='text-gray-600 text-[11px] sm:text-[12px] md:text-[14px] '>We usually update our followers 2 times a year. If you think you are being contacted frequently, please inform us and we can limit this to less updates or if you would prefer, take you out from the e-mailing list completely.                            </p>
                        </div>


                        <div className='flex flex-col gap-y-3 '>
                            {/* <p className='font-bold text-[14px] sm:text-[16px] md:text-[20px] text-gray-600'>2. How We Use Your Information</p> */}
                            <p className='text-gray-600 text-[11px] sm:text-[12px] md:text-[14px] '>You can also request a copy of the personal info we have for you at any time by emailing to support@kenmai.org . If any personal information changes or you find our records are not up-to-date, please inform us.                            </p>
                              </div>

                        <div className='flex flex-col gap-y-3 '>
                            {/* <p className='font-bold text-[14px] sm:text-[16px] md:text-[20px] text-gray-600'>3. Sharing Your Information</p> */}
                            <p className='text-gray-600 text-[11px] sm:text-[12px] md:text-[14px] '>We will make sure your personal information is held safely, as per the Data Protection Act. </p>
                        </div>

                    </div>
                </div>
            </section>

        </>
    )
}

export default Privacy