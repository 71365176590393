import React from 'react'

import banner from '../images/banner/banner14.jpg'

import qr from '../images/qr.jpg'
import Razorpay from './Razorpay'

const Donate = () => {

    const handleContextMenu = (event) => {
        event.preventDefault();
      };

    return (
        <>

            <div className='overflow-hidden' onContextMenu={handleContextMenu}>

                <div className=' w-full fixed top-0 -z-30 h-screen'>
                    <div className='relative'>

                        <img src={banner} className='w-full object-cover h-screen' alt="" />
                        <div className='absolute inset-0 backdrop-blur-sm bg-teal-300/10'></div>
                    </div>
                </div>



                <section>
                    <div className=' w-full px-4 md:w-[80%] mx-auto mt-10 md:mt-20 mb-20'>

                        <div className='flex justify-center items-center'>

                            {/* <div className='w-full flex justify-center'>
                                <img src={qr} className='w-[40%] md:w-[15%] object-cover' alt="" />
                            </div> */}


                            <div className='w-full'>
                                <Razorpay />
                            </div>

                        </div>

                        <div className='flex justify-center mt-8 md:mt-16'>
                            <div className='flex flex-col gap-y-2 text-[12px] md:text-[14px] font-[poppins] text-gray-950'>

                                <p>Banking name : Kenmai Foundation</p>
                                <p>Acc no : 110186358857</p>
                                <p>Ifsc code : CNRB0005652</p>
                                <p>Bank : Canara Bank</p>

                            </div>
                        </div>







                    </div>
                </section>


            </div>

        </>
    )
}

export default Donate