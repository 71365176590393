import React from 'react'
import { useState, useEffect } from 'react';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import { Link } from 'react-router-dom';
// import { Link } from 'react-scroll';

import { FaWhatsapp } from "react-icons/fa";


import logo from '../images/kenmai_logo3.png'
import { FaXTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { FiFacebook } from "react-icons/fi";
import { PiLinkedinLogoBold } from "react-icons/pi";








const Header = () => {

    // State to manage the navbar's visibility
    const [nav, setNav] = useState(false);


    // Toggle function to handle the navbar's display
    const handleNav = () => {
        setNav(!nav);
    };


    //color change
    const [navbarBg, setNavbarBg] = useState('bg-white');


    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setNavbarBg('bg-gray-50 shadow-md ');
            } else {
                setNavbarBg('bg-white');
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Remove event listener on cleanup
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    function topPage() {
        window.scroll(0, 0)
    }

    //mobile view click the link hide the navbar

    const [hide, setHide] = useState(true)

    function clicklink() {
        setHide(false)
    }


    return (
        <>

            {/* ---------- first navbar ---------------------- */}

            <div className='bg-gray-600 py-1.5 md:py-2.5 font-[roboto]'>
                <div className='w-[90%] mx-auto'>

                    <div className='flex items-center justify-between'>

                        <div>
                            <a href="tel:+917418616456" className='text-[10px] sm:text-[12px] md:text-[14px] font-semibold text-white'> Call Us: +91 7418616456</a>

                        </div>

                        <div className='flex items-center gap-x-4  md:gap-x-6'>

                            <div className='bg-white h-4 w-4 md:h-6  md:w-6 rounded-full flex justify-center items-center shadow-md drop-shadow-md cursor-pointer hover:animate-bounce'>
                                <FaXTwitter className='text-gray-950 text-[10px] md:text-[13px]  ' />
                            </div>

                            <div className='bg-white h-4 w-4 md:h-6  md:w-6 rounded-full flex justify-center items-center shadow-md drop-shadow-md cursor-pointer hover:animate-bounce'>

                                <FaInstagram className='text-gray-950 text-[10px] md:text-[13px] ' />
                            </div>

                            <div className='bg-white h-4 w-4 md:h-6 md:w-6 rounded-full flex justify-center items-center shadow-md drop-shadow-md cursor-pointer hover:animate-bounce'>

                                <FiFacebook className='text-gray-950 text-[10px] md:text-[13px] ' />
                            </div>

                            <div className='bg-white h-4 w-4 md:h-6 md:w-6 rounded-full flex justify-center items-center shadow-md drop-shadow-md cursor-pointer hover:animate-bounce'>
                                <Link to='https://www.linkedin.com/in/kenmai-foundation-b0520b318?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app ' target='_blank'>
                                    <PiLinkedinLogoBold className='text-gray-950 text-[10px] md:text-[13px] ' />
                                </Link>
                            </div>


                        </div>
                    </div>
                </div>

            </div >

            {/* -------- second navbar ------- */}

            < nav className={` w-full sticky top-0 z-50  py-4 md:py-4 border-b  border-gray-100 drop-shadow-sm ${navbarBg} `
            }>
                <div className="max-w-full mx-auto font-[poppins] ">
                    {/* Logo */}
                    <div className='flex justify-between items-center px-4 sm:px-9 w-full xl:w-[90%] mx-auto'>


                        <div className='w-full md:w-fit'>
                            <Link to='/' onClick={topPage}>
                                <img src={logo} className='w-20 sm:w-24 md:w-40 object-cover' alt="" />
                            </Link>
                        </div>


                        {/* Desktop Navigation */}
                        <ul className='hidden lg:flex w-full md:w-fit font-bold gap-x-4 xl:gap-x-9 font-[roboto]'  >


                            <li className='  cursor-pointer duration-300 text-gray-800  text-[12px] xl:text-[14px] ' >
                                <Link to="/" onClick={topPage}>
                                    <p className="relative group flex items-center">
                                        <button class="tracking-[.02em] hover:text-black/65 font-bold  focus:text-red-600">Home</button>
                                    </p>
                                </Link>
                            </li>


                            <li className='  cursor-pointer duration-300 text-gray-800  text-[12px] xl:text-[14px] ' >
                                <Link to="/about" onClick={topPage}>
                                    <p className="relative group flex items-center">
                                        <button class="tracking-[.02em]  hover:text-black/65 font-bold  focus:text-red-600">About Us</button>
                                    </p>
                                </Link>
                            </li>


                            <li className='  cursor-pointer duration-300 text-gray-800 font-bold text-[12px] xl:text-[14px]' >

                                <Link to="/our-programs" onClick={topPage}>
                                    <p className="relative group flex items-center">
                                        <button class=" tracking-[.02em]  hover:text-black/65  focus:text-red-600">Our Programs</button>
                                    </p>
                                </Link>

                            </li>
                            <li className='/cursor-pointer duration-300 text-gray-800 font-bold text-[12px] xl:text-[14px]' >

                                <Link to="volunteer-with-us" onClick={topPage}>
                                    <p className="relative group flex items-center">
                                        <button class=" tracking-[.02em]  hover:text-black/65  focus:text-red-600">Volunteer With Us </button>
                                    </p>
                                </Link>

                            </li>

                            <li className=' cursor-pointer duration-300 text-gray-800 font-bold text-[12px] xl:text-[14px]' >

                                <Link to="/partner-with-us" smooth={true} duration={500} onClick={topPage}>
                                    <p className="relative group flex items-center">
                                        <button class=" tracking-[.02em]  hover:text-black/65  focus:text-red-600"> Partner With Us </button>
                                    </p>
                                </Link>

                            </li>

                            {/* <li className=' cursor-pointer duration-300 text-gray-800 font-bold text-[12px] xl:text-[14px]' >

                                <Link to="mentors" smooth={true} duration={500} onClick={topPage}>
                                    <p className="relative group flex items-center">
                                        <button class=" tracking-[.02em]  hover:text-black/65  focus:text-red-600"> WORK WITH US </button>
                                    </p>
                                </Link>

                            </li> */}
                            <li className=' cursor-pointer duration-300 text-gray-800 font-bold text-[12px] xl:text-[14px]' >

                                <Link to="/contact" onClick={topPage}>
                                    <p className="relative group flex items-center">
                                        <button class=" tracking-[.02em]  hover:text-black/65  focus:text-red-600">Contact</button>
                                    </p>
                                </Link>
                            </li>

                        </ul>

                        <div className='cursor-pointer w-fit'>
                            <Link to='donate'>
                                <button className='shadow-lg drop-shadow-sm hover:scale-105 duration-500 shadow-gray-300 px-3 py-1.5 md:px-7 md:py-2.5 uppercase text-[12px] md:text-[14px] font-semibold bg-[#3263e4] rounded-md md:rounded-lg text-white hover:text-gray-800 border border-gray-100 hover:border hover:border-blue-600 hover:bg-white'>Donate</button>
                            </Link>


                        </div>


                        {/* Mobile Navigation Icon */}
                        <div onClick={handleNav} className='block lg:hidden'>
                            {nav ? <AiOutlineClose className='w-16 sm:w-16 md:w-20 text-gray-900' /> : <AiOutlineMenu className='w-16 sm:w-16 md:w-20 text-gray-900' />}
                        </div>

                        {/* Mobile Navigation Menu */}
                        {
                            hide &&
                            <ul
                                className={
                                    nav
                                        ? 'z-40 h-screen fixed lg:hidden left-0 top-0 w-[60%]  border-r   bg-gray-100  ease-in-out duration-500 font-[roboto]'
                                        : 'ease-in-out w-[60%] duration-500 fixed top-0 bottom-0 left-[-100%]'
                                }
                            >
                                {/* Mobile Logo */}

                                <Link to='/' onClick={topPage} className='w-full' >
                                    <img src={logo} className=' w-24 sm:w-28  pt-10  ml-10' alt="" onClick={handleNav} />
                                </Link>



                                {/* Mobile Navigation Items */}

                                <li className=' ml-10 cursor-pointer duration-300 text-gray-500  text-[13px] mt-8' >
                                    <Link to="/" onClick={topPage}>
                                        <p className="relative group flex items-center">
                                            <button class="font-bold tracking-[.02em]  hover:text-white/50  focus:text-red-600" onClick={handleNav}>Home</button>
                                        </p>

                                    </Link>
                                </li>

                                <li className=' ml-10 cursor-pointer duration-300 text-gray-500  text-[13px] mt-8' >
                                    <Link to="/about" onClick={topPage}>
                                        <p className="relative group flex items-center">
                                            <button class="font-bold tracking-[.02em]  hover:text-white/50  focus:text-red-600" onClick={handleNav}>About Us</button>
                                        </p>

                                    </Link>
                                </li>

                                <li className=' ml-10 cursor-pointer duration-300 text-gray-500  text-[13px] mt-8' >
                                    <Link to="/our-programs" onClick={topPage}>

                                        <p className="relative group flex items-center">
                                            <button class="font-bold tracking-[.02em]  hover:text-white/50  focus:text-red-600" onClick={handleNav}>Our Programs</button>
                                        </p>
                                    </Link>

                                </li>

                                <li className=' ml-10 cursor-pointer duration-300 text-gray-500  text-[13px] mt-8' >
                                    <Link to="/volunteer-with-us " onClick={topPage}>
                                        <p className="relative group flex items-center">
                                            <button class="font-bold tracking-[.02em]  hover:text-white/50  focus:text-red-600" onClick={handleNav}>Volunteer With Us </button>
                                        </p>
                                    </Link>
                                </li>

                                <li className=' ml-10 cursor-pointer duration-300 text-gray-500  text-[13px] mt-8' >
                                    <Link to="/partner-with-us " onClick={topPage}>
                                        <p className="relative group flex items-center">
                                            <button class="font-bold tracking-[.02em]  hover:text-white/50  focus:text-red-600" onClick={handleNav}>Partner With Us </button>
                                        </p>
                                    </Link>
                                </li>



                                <li className=' ml-10 cursor-pointer duration-300 text-gray-500  text-[13px] mt-8' >
                                    <Link to="/contact" onClick={topPage}>

                                        <p className="relative group flex items-center">
                                            <button class="font-bold tracking-[.02em]  hover:text-white/50  focus:text-red-600" onClick={handleNav}>Contact</button>
                                        </p>

                                    </Link>
                                </li>
                            </ul>
                        }

                    </div>
                </div>
            </nav >

        </>
    )
}

export default Header
