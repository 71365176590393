import React, { useEffect } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

import banner from '../images/banner/banner12.jpg'

import v1 from '../images/v1.jpg'
import v2 from '../images/v2.jpg'
import v3 from '../images/v3.jpg'

const Volunteer = () => {
    useEffect(() => {
        AOS.init({ duration: 1000 })
    }, [])

    return (
        <>

            <div className='overflow-hidden'>

                <section>
                    <div className='relative font-[poppins]'>


                        <div className='relative'>
                            <img src={banner} className='w-full' alt="" />
                            <div className='absolute inset-0 backdrop-blur-sm bg-teal-500/15'></div>
                        </div>

                        <div className='absolute top-8 md:top-32 w-full flex justify-center '>

                            <h3 className='font-bold text-[20px] md:text-[65px] tracking-[2px] md:tracking-[8px] uppercase text-white' >Volunteer With Us</h3>

                        </div>

                    </div>
                </section>

                <section className='mt-8 md:mt-20  mb-8 md:mb-20 font-[poppins]'>
                    <div className='w-full px-4 md:w-[80%] mx-auto space-y-8 md:space-y-24'>

                        <div className='flex flex-col md:flex-row justify-center items-center gap-y-8'>

                            <div className='w-full md:w-[50%] mx-auto flex justify-center md:justify-start' data-aos='fade-right'>

                                <div className='relative w-full'>
                                    <img src={v2} className=' w-full md:w-[80%] rounded-xl drop-shadow-lg shadow-md shadow-black/10' alt="" />
                                    {/* <img src={v1} className='absolute -top-5 -left-5 w-36 rounded-l-xl' alt="" /> */}
                                </div>

                            </div>

                            <div className='w-full md:w-[50%] mx-auto flex justify-center' data-aos='fade-left'>

                                <div>
                                    <h3 className='font-bold text-[20px] md:text-[24px] uppercase'>Community Outreach</h3>
                                    <p className='text-[12px] md:text-[14px] text-gray-800 mt-3'>
                                        Participate in awareness campaigns, educating communities about the importance of education, sustainable livelihoods, and poverty alleviation.
                                        Assist in the distribution of essential supplies, such as food packets and ration kits, to those in need.
                                        Engage with local communities, fostering collaboration and ensuring our initiatives are tailored to their specific needs.
                                    </p>
                                </div>

                            </div>

                        </div>



                        <div className='flex flex-col  md:flex-row-reverse justify-center items-center gap-y-8'>

                            <div className='w-full md:w-[50%] mx-auto flex justify-center md:justify-end' data-aos='fade-left'>

                                <img src={v3} className=' w-full md:w-[80%] ' alt="" />

                            </div>

                            <div className='w-full md:w-[50%] mx-auto flex justify-center' data-aos='fade-right'>

                                <div>
                                    <h3 className='font-bold text-[20px] md:text-[24px] uppercase'>Teaching and Mentoring</h3>
                                    <p className='text-[12px] md:text-[14px] text-gray-800 mt-3'>
                                        Volunteer as a teacher or tutor in our educational programs, helping children and adults gain access to quality education.
                                        Mentor students, providing guidance, support, and encouragement to help them achieve their full potential.
                                    </p>
                                </div>

                            </div>

                        </div>

                    </div>
                </section>


            </div>

        </>
    )
}

export default Volunteer