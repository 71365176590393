import React, { useEffect } from 'react'

const Terms = () => {




    return (
        <>

            <section className='bg-gray-50 flex  px-3 py-3 md:p-8 font-[roboto]' >
                <div className='w-full  md:w-[85%] bg-white p-4 rounded-md border-2 border-gray-100 drop-shadow-md shadow-lg mx-auto'>

                    <div className='my-3'>
                        <h3 className='text-gray-900 font-bold uppercase text-center font-[poppins] text-[14px] sm:text-[16px] md:text-[22px]'>Terms and Conditions for Kenmai Foundation                        </h3>
                    </div>

                    <div className=' my-1 md:my-3'>
                        <p className='text-gray-600 text-[11px] sm:text-[12px] md:text-[14px] '>Please read these Terms and Conditions carefully before using the Kenmai Foundation website.                        </p>
                    </div>

                    <div className='mt-8 flex flex-col  gap-y-4 md:gap-y-6 px-1 md:px-5'>

                        <div className='flex flex-col gap-y-3 '>
                            <p className='font-bold text-[14px] sm:text-[16px] md:text-[20px] text-gray-600'>1. Acceptance of Terms</p>
                            <p className='text-gray-600 text-[11px] sm:text-[12px] md:text-[14px] '>By accessing or using the Kenmai Foundation website, you agree to be bound by these Terms and Conditions. If you disagree with any part of the terms, you may not access the website.
                            </p>
                        </div>

                        <div className='flex flex-col gap-y-3 '>
                            <p className='font-bold text-[14px] sm:text-[16px] md:text-[20px] text-gray-600'>2. Use of Website                            </p>

                            <p className='text-gray-600 text-[11px] sm:text-[12px] md:text-[14px] '>Eligibility: You must be at least 18 years old to use this website and donate.  </p>
                            <p className='text-gray-600 text-[11px] sm:text-[12px] md:text-[14px] '> User Account: Some areas of the website may require you to enter your basicinformation.                                 </p>

                            <p className='text-gray-600 text-[11px] sm:text-[12px] md:text-[14px] '>Prohibited Activities: You agree not to:                            </p>
                            <p className='text-gray-600 text-[11px] sm:text-[12px] md:text-[14px] '> Use the website in any way that violates any applicable laws                            </p>
                            <p className='text-gray-600 text-[11px] sm:text-[12px] md:text-[14px] '>Use the website to transmit any malicious software                            </p>
                            <p className='text-gray-600 text-[11px] sm:text-[12px] md:text-[14px] '>Attempt to gain unauthorized access to any portion of the website                            </p>
                            <p className='text-gray-600 text-[11px] sm:text-[12px] md:text-[14px] '>Use the website in any manner that could disable, overburden, or impair the site                            </p>
                        </div>


                        <div className='flex flex-col gap-y-3 '>
                            <p className='font-bold text-[14px] sm:text-[16px] md:text-[20px] text-gray-600'>3. Intellectual Property                            </p>
                            <p className='text-gray-600 text-[11px] sm:text-[12px] md:text-[14px] '>Ownership: The website and its original content, features, and functionality are owned by Kenmai Foundation and are protected by international and national copyright, trademark, and other intellectual property laws.                            </p>
                        </div>

                        <div className='flex flex-col gap-y-3 '>
                            <p className='font-bold text-[14px] sm:text-[16px] md:text-[20px] text-gray-600'>4. Donations and Fundraising</p>
                            <p className='text-gray-600 text-[11px] sm:text-[12px] md:text-[14px] '>Donations: All donations are subject to our Refund Policy. </p>
                            <p className='text-gray-600 text-[11px] sm:text-[12px] md:text-[14px] '>Use of Funds: Kenmai Foundation reserves the right to use donations in a manner consistent with its mission and objectives.                            </p>
                        </div>


                        <div className='flex flex-col gap-y-3 '>
                            <p className='font-bold text-[14px] sm:text-[16px] md:text-[20px] text-gray-600'>5. Privacy</p>
                            <p className='text-gray-600 text-[11px] sm:text-[12px] md:text-[14px] '>Your use of the website is also governed by our Privacy Policy. </p>
                        </div>

                        <div className='flex flex-col gap-y-3 '>
                            <p className='font-bold text-[14px] sm:text-[16px] md:text-[20px] text-gray-600'>6. Third-Party Links                            </p>
                            <p className='text-gray-600 text-[11px] sm:text-[12px] md:text-[14px] '> The website may contain links to third-party websites. Kenmai Foundation is not responsible for the content or practices of any linked third-party sites.                            </p>


                        </div>


                        <div className='flex flex-col gap-y-3 '>
                            <p className='font-bold text-[14px] sm:text-[16px] md:text-[20px] text-gray-600'>7. Disclaimer of Warranties                            </p>
                            <p className='text-gray-600 text-[11px] sm:text-[12px] md:text-[14px] '>The website is provided on an "as is" and "as available" basis. Kenmai Foundation makes no warranties, expressed or implied, regarding the operation of the website or the information, content, or materials included on the website.                            </p>

                        </div>


                        <div className='flex flex-col gap-y-3 '>
                            <p className='font-bold text-[14px] sm:text-[16px] md:text-[20px] text-gray-600'>8. Limitation of Liability                            </p>
                            <p className='text-gray-600 text-[11px] sm:text-[12px] md:text-[14px] '>Kenmai Foundation shall not be liable for any indirect, incidental, special, consequential or punitive damages resulting from your use of, or inability to use, the website.                            </p>
                        </div>

                        <div className='flex flex-col gap-y-3 '>
                            <p className='font-bold text-[14px] sm:text-[16px] md:text-[20px] text-gray-600'>9. Indemnification                            </p>
                            <p className='text-gray-600 text-[11px] sm:text-[12px] md:text-[14px] '>You agree to indemnify and hold Kenmai Foundation harmless from any claim or demand made by any third party due to or arising out of your breach of these Terms and Conditions.                            </p>
                        </div>

                        <div className='flex flex-col gap-y-3 '>
                            <p className='font-bold text-[14px] sm:text-[16px] md:text-[20px] text-gray-600'>10. Changes to Terms                            </p>
                            <p className='text-gray-600 text-[11px] sm:text-[12px] md:text-[14px] '> Kenmai Foundation reserves the right to modify these Terms and Conditions at any time. We will notify users of any changes by updating the "Last updated" date of these Terms and Conditions.</p>
                        </div>


                        <div className='flex flex-col gap-y-3 '>
                            <p className='font-bold text-[14px] sm:text-[16px] md:text-[20px] text-gray-600'>11. Governing Law                            </p>
                            <p className='text-gray-600 text-[11px] sm:text-[12px] md:text-[14px] '>These Terms shall be governed by and construed in accordance with the laws and without regard to its conflict of law provisions.                            </p>
                        </div>

                        <div className='flex flex-col gap-y-3 '>
                            <p className='font-bold text-[14px] sm:text-[16px] md:text-[20px] text-gray-600'>12. Contact Information                            </p>
                            <p className='text-gray-600 text-[11px] sm:text-[12px] md:text-[14px] '>If you have any questions about these Terms, please contact us at support@kenmai.org. </p>
                        </div>


                    </div>
                </div>
            </section>

        </>
    )
}

export default Terms