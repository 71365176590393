import React, { useEffect } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

import banner from '../images/banner/partner.png'

import v1 from '../images/v1.jpg'
import v2 from '../images/all/cor.png'
import v3 from '../images/all/cor_1.png'

const Partner = () => {
    useEffect(() => {
        AOS.init({ duration: 1000 })
    }, [])

    return (
        <>

            <div className='overflow-hidden'>

                <section>
                    <div className='relative font-[poppins]'>


                        <div className='relative'>
                            <img src={banner} className='w-full' alt="" />
                            <div className='absolute inset-0 backdrop-blur-[3px] bg-teal-500/15'></div>
                        </div>

                        <div className='absolute top-8 md:top-32 w-full flex justify-center '>

                            <h3 className='font-bold text-[20px] md:text-[65px] tracking-[2px] md:tracking-[8px] uppercase text-white' >partner With Us</h3>

                        </div>

                    </div>
                </section>

                <section className='mt-8 md:mt-20  mb-8 md:mb-20 font-[poppins]'>
                    <div className='w-full px-4 md:w-[80%] mx-auto space-y-8 md:space-y-24'>

                        <div className='flex flex-col md:flex-row justify-center items-center gap-y-8'>

                            <div className='w-full md:w-[50%] mx-auto flex justify-center md:justify-start' data-aos='fade-right'>

                                <div className='relative w-full'>
                                    <img src={v2} className=' w-full md:w-[80%] drop-shadow-lg ' alt="" />
                                    {/* <img src={v1} className='absolute -top-5 -left-5 w-36 rounded-l-xl' alt="" /> */}
                                </div>

                            </div>

                            <div className='w-full md:w-[50%] mx-auto flex justify-center' data-aos='fade-left'>

                                <div>
                                    <h3 className='font-bold text-[18px] md:text-[24px] uppercase'>Corporate Social Responsibility</h3>
                                    <p className='text-[12px] md:text-[14px] text-gray-800 mt-3'>
                                        At Kenmai Foundation, we believe in the power of collaboration and partnerships to create lasting change.
                                        We invite corporations to align their corporate social responsibility (CSR) goals with our mission of empowering communities through education,
                                        livelihood opportunities, and poverty alleviation.
                                    </p>

                                    <p className='text-[12px] md:text-[14px] text-gray-800 mt-3'>
                                        By partnering with Kenmai Foundation, your corporation can make a meaningful impact on underserved communities while fostering a culture of social responsibility and employee engagement.
                                    </p>

                                </div>

                            </div>

                        </div>



                        <div className='flex flex-col  md:flex-row-reverse justify-center items-center gap-y-8'>

                            <div className='w-full md:w-[50%] mx-auto flex justify-center md:justify-end' data-aos='fade-left'>

                                <img src={v3} className=' w-full md:w-[80%] ' alt="" />

                            </div>

                            <div className='w-full md:w-[50%] mx-auto flex justify-center' data-aos='fade-right'>

                                <div>
                                    <h3 className='font-bold text-[18px] md:text-[24px] uppercase'>Employee Volunteering</h3>
                                    <p className='text-[12px] md:text-[14px] text-gray-800 mt-3'>
                                        At Kenmai Foundation, we recognize the immense value that corporate volunteers bring to our mission of empowering communities through education, livelihood opportunities, and poverty alleviation. By engaging your employees in our initiatives,
                                        you not only contribute to social impact but also foster a culture of purpose, teamwork, and corporate social responsibility.
                                    </p>

                                    <p className='text-[12px] md:text-[14px] text-gray-800 mt-3'>
                                        We offer a range of corporate volunteering opportunities that align with your organization's values and allow your employees to contribute their skills, expertise, and time to make a tangible difference.
                                    </p>
                                </div>

                            </div>

                        </div>

                    </div>
                </section>


            </div>

        </>
    )
}

export default Partner;