import React from 'react'
import Slider from "react-slick";

import banner from '../images/banner/banner7.png'
import about from '../images/all/img8.png'

import a1 from '../images/kenmai/a1.jpg'
import a2 from '../images/kenmai/a2.jpg'
import a3 from '../images/kenmai/a3.jpg'
import a4 from '../images/kenmai/a4.jpg'
import a5 from '../images/kenmai/a5.jpg'
import a6 from '../images/kenmai/a6.jpg'


//icons
import { TbEyeStar } from "react-icons/tb";
import { MdOutlineManageAccounts } from "react-icons/md";


const About = () => {

    const settings = {
        className: "center",
        infinite: true,
        autoplay: true,
        slidesToScroll: 1,
        centerPadding: "60px",
        slidesToShow: 3,
        speed: 4000,
        autoplaySpeed: 2000,
        cssEase: "linear",
        swipeToSlide: true,
        afterChange: function (index) {
            console.log(`Slider Changed to: ${index + 1}, background: #222; color: #bada55`);
        },
        responsive: [
            {
                breakpoint: 2500,
                settings: {
                    slidesToShow: 4,
                    centerPadding: "30px",
                }
            },
            {
                breakpoint: 2000,
                settings: {
                    slidesToShow: 3,
                    centerPadding: "30px",
                }
            },
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 3,
                    centerPadding: "30px",
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    centerPadding: "30px",
                }
            },

            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    centerPadding: "20px",
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    centerPadding: "15px",
                }
            }
        ]
    };

    return (
        <>

            <div>

                <section>
                    <div>
                        <img src={banner} className='w-full object-cover ' alt="" />
                    </div>

                </section>

                <section>

                    <div className=' w-full px-6 md:w-[80%] mx-auto mt-8 md:mt-20 mb-6 md:mb-20 font-[poppins]'>

                        <div className='flex flex-col md:flex-row items-center gap-y-6  '>

                            <div className='w-full md:w-[50%] mx-auto'>

                                <h2 className='font-bold text-[20px] md:text-[24px] mb-2 md:mb-4'>About Us</h2>
                                <p className='font-[roboto] text-[12px] md:text-[14px] leading-6 mb-3 md:mb-4'>
                                    Kenmai Foundation was started by a group of friends with a decade of experience working in the social sector.
                                    Driven by a shared vision to empower communities through education, livelihood opportunities, and the fight against
                                    poverty, we came together to establish this non-profit organization.
                                </p>
                                <p className='font-[roboto] text-[12px] md:text-[14px] leading-6'> <span className='font-semibold '>Our Founders:</span> Senthil Kumar and two other friends are the founding trustees of Kenmai Foundation.
                                    With their collective expertise and unwavering commitment to social change, they have spearheaded the organization's
                                    mission to create lasting, positive change in underserved communities.
                                </p>
                            </div>

                            <div className='w-full md:w-[50%] mx-auto flex justify-center'>

                                <img src={about} className=' w-full md:w-[80%] rounded-lg shadow-lg drop-shadow-md object-cover' alt="" />

                            </div>
                        </div>

                    </div>
                </section>

                <section className='bg-[#FAF9F6]'>
                    <div className=' w-full px-6 md:w-[80%] mx-auto font-[poppins] pt-10 md:pt-20 pb-10 md:pb-20'>

                        <div className='grid grid-cols-1 md:grid-cols-2 gap-y-9 gap-x-20'>

                            <div className='bg-white rounded-md shadow-md drop-shadow-lg shadow-gray-300 px-4 py-3 md:py-6'>

                                <div className='flex flex-col justify-center items-center gap-y-3'>
                                    <div>
                                        <TbEyeStar className='text-orange-500 text-[30px] md:text-[40px]' />
                                    </div>

                                    <div>
                                        <h3 className='text-center font-bold text-[16px] md:text-[20px] mb-2 text-gray-900 '>Our Vision</h3>
                                        <p className='text-center text-gray-500 text-[11px] md:text-[14px]'>Kenmai Foundation aspires to empower communities through education and sustainable livelihoods, overcoming poverty and fostering a world of enduring positive change.</p>
                                    </div>

                                </div>

                            </div>

                            <div className='bg-white rounded-md shadow-md drop-shadow-lg shadow-gray-300 px-4 py-3 md:py-6'>

                                <div className='flex flex-col justify-center items-center gap-y-3'>
                                    <div>
                                        <MdOutlineManageAccounts className='text-orange-500 text-[30px] md:text-[40px]' />
                                    </div>

                                    <div>
                                        <h3 className='text-center font-bold text-[16px] md:text-[20px] mb-2 text-gray-900 '>Our Mission </h3>
                                        <p className='text-center text-gray-500 text-[11px] md:text-[14px]'>At Kenmai Foundation, we strive to empower communities through education, livelihood, and the fight against poverty. By providing quality education, fostering skill development, and addressing the root causes of poverty, we aim to create lasting, positive change.</p>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>
                </section>


                <section className='flex justify-center items-center'>
                    <div className=' w-full px-6 md:w-[80%] mx-auto  pt-10 md:pt-16 pb-10 md:pb-20'>

                        <div className="slider-container">
                            <Slider {...settings} className='flex justify-center items-center'>
                                <div className='px-5 md:px-2  '>
                                    <img src={a1} className='w-full md:w-[80%] object-cover rounded-md shadow-md drop-shadow-md shadow-black/20' alt="" />
                                </div>
                                <div className='px-5 md:px-2 '>
                                    <img src={a2} className='w-full md:w-[80%] object-cover rounded-md shadow-md drop-shadow-md shadow-black/20' alt="" />
                                </div>
                                <div className='px-5 md:px-2 '>
                                    <img src={a3} className='w-full md:w-[80%] object-cover rounded-md shadow-md drop-shadow-md shadow-black/20' alt="" />
                                </div>
                                <div className='px-5 md:px-2 '>
                                    <img src={a4} className='w-full md:w-[80%] object-cover rounded-md shadow-md drop-shadow-md shadow-black/20' alt="" />
                                </div>
                                <div className='px-5 md:px-2 '>
                                    <img src={a5} className='w-full md:w-[80%] object-cover rounded-md shadow-md drop-shadow-md shadow-black/20' alt="" />
                                </div>
                                <div className='px-5 md:px-2 '>
                                    <img src={a6} className='w-full md:w-[80%] object-cover rounded-md shadow-md drop-shadow-md shadow-black/20' alt="" />
                                </div>

                            </Slider>
                        </div>


                    </div>
                </section>

            </div>

        </>
    )
}

export default About