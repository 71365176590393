import React, { useEffect } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'
import Slider from "react-slick";

import background from '../images/banner/banner5.jpg'

import bannerOne from '../images/banner/b_1.jpg'
import bannerTwo from '../images/banner/b_2.png'
import bannerThree from '../images/banner/b_3.png'

import education from '../images/all/img9.png'
import live from '../images/all/img1.1.jpg'
import livelihood from '../images/all/img3.jpg'
import community from '../images/all/img5.png'
import join from '../images/all/img7.jpg'

import r1 from '../images/all/r1.jpg'
import r2 from '../images/all/r2.jpg'
import r3 from '../images/all/r3.jpg'
import r4 from '../images/all/r4.jpg'

import './Home.css'
import { Link } from 'react-router-dom';


//icons
import { BsPersonRaisedHand } from "react-icons/bs";
import { RiHandHeartFill } from "react-icons/ri";
import { GiTakeMyMoney } from "react-icons/gi";



const Home = () => {

    const text = "OUR HIGHLIGHTS";

    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 5000,
        cssEase: "linear"
    };

    function toppage() {
        window.scrollTo(0, 0);
    }

    useEffect(() => {
        AOS.init({ duration: 1000 })
    }, [])


    return (
        <>
            <div className='overflow-hidden'>

                <div className='fixed top-0 w-full -z-30 backdrop-saturate-50 bg-black/75'>
                    <img src={background} className='object-cover w-full  blur-[16px]' alt="" />
                </div>

                <section className='bg-white font-[Cardo]'>
                    <div>

                        <div className="slider-container">
                            <Slider {...settings}>

                                <div className='relative'>
                                    <div>
                                        <img src={bannerThree} className='w-full object-cover' alt="" />
                                    </div>

                                    {/* <div className='absolute w-full top-12 md:top-52 flex justify-center items-center'>

                                        <div>
                                            <h2 className='text-center font-extrabold text-[16px] md:text-[60px] text-orange-500 uppercase'>Health, Healing, and  </h2>
                                            <h2 className='text-center font-extrabold text-[16px] md:text-[60px] text-orange-500 uppercase'>Happiness for All. </h2>
                                            <p className='text-center font-semibold text-[12px] md:text-[28px] text-gray-800'>Together, we can create a brighter, healthier tomorrow.</p>
                                        </div>

                                    </div> */}
                                </div>

                                <div className='relative'>
                                    <div>
                                        <img src={bannerTwo} className='w-full object-cover' alt="" />
                                    </div>

                                    {/* <div className='absolute w-full top-12 md:top-52 flex justify-center items-center'>

                                        <div>
                                            <h2 className='text-center font-extrabold text-[16px] md:text-[60px] text-orange-500 uppercase'>Empowering Children, </h2>
                                            <h2 className='text-center font-extrabold text-[16px] md:text-[60px] text-orange-500 uppercase'>Shaping Tomorrow </h2>
                                            <p className='text-center font-semibold text-[12px] md:text-[28px]  text-gray-50'>Join us in our mission to create a brighter future for every child.</p>
                                        </div>

                                    </div> */}
                                </div>
                                <div className='relative'>
                                    <div>
                                        <img src={bannerOne} className='w-ful object-cover' alt="" />
                                    </div>

                                    {/* <div className='absolute w-full top-12 md:top-52 flex justify-center items-center'>

                                        <div>
                                            <h2 className='text-center font-extrabold text-[16px] md:text-[60px] text-orange-500 uppercase'>Hope, Education, and </h2>
                                            <h2 className='text-center font-extrabold text-[16px] md:text-[60px] text-orange-500 uppercase'>Opportunity for All </h2>
                                            <p className='text-center font-semibold text-[12px] md:text-[28px] text-gray-800'>Together, we can make a difference in the lives of children.</p>
                                        </div>

                                    </div> */}
                                </div>

                            </Slider>
                        </div>
                    </div>
                </section>


                <section className='pt-12 md:pt-20 pb-8 md:pb-20 bg-gray-50 font-[poppins]'>
                    <div className='w-[85%] mx-auto'>

                        <div className='flex justify-center items-center'>

                            <div className='bg-[#f5fdff] px-5 py-4 rounded-lg shadow-md drop-shadow-md' data-aos='fade-up' data-aos-duration='1000' data-aos-delay='30'>
                                <div className='flex items-center gap-x-5'>

                                    <div className='bg-gradient-to-b from-[#6570eb] to-[#3263e4] px-3 py-2 rounded-lg'>
                                        <BsPersonRaisedHand className='text-white text-[40px] md:text-[60px]' />
                                    </div>
                                    <div>
                                        <h2 className='text-[14px] md:text-[16px] uppercase font-semibold'>Become a volunteer</h2>
                                        <p className='text-[10px] md:text-[12px] mt-2'>Alone, I can do little. Together, we can do anything.</p>
                                    </div>

                                </div>
                            </div>


                            {/* <div className='bg-[#fffcf4] px-5 py-4 rounded-lg shadow-md drop-shadow-md' data-aos='fade-up' data-aos-duration='2000' data-aos-delay='50'>
                                <div className='flex items-center gap-x-5'>

                                    <div className='bg-gradient-to-b from-orange-300 to-orange-500 px-3 py-2 rounded-lg'>
                                        <RiHandHeartFill className='text-white text-[40px] md:text-[60px]' />
                                    </div>
                                    <div>
                                        <h2 className='text-[14px] md:text-[16px] uppercase font-semibold'> Quick fundraising</h2>
                                        <p className='text-[10px] md:text-[12px] mt-2'>Join our quick fundraising to make an immediate impact.</p>
                                    </div>

                                </div>
                            </div> */}




                            {/* <div className='bg-[#f4fffa] px-5 py-4 rounded-lg shadow-md drop-shadow-md' data-aos='fade-up' data-aos-duration='3000' data-aos-delay='80'>
                                <div className='flex items-center gap-x-5'>

                                    <div className='bg-gradient-to-b from-pink-400 to-pink-600 px-3 py-2 rounded-lg'>
                                        <GiTakeMyMoney className='text-white text-[40px] md:text-[60px]' />
                                    </div>
                                    <div>
                                        <h2 className='text-[14px] md:text-[16px] uppercase font-semibold'>Start donating</h2>
                                        <p className='text-[10px] md:text-[12px] mt-2'>Start donating today to make an immediate impact.</p>
                                    </div>

                                </div>
                            </div> */}


                        </div>

                    </div>
                </section>

                {/* ------------ our approch ----------- */}

                <section className='bg-white pt-10 md:pt-20 pb-6 md:pb-24 ' >
                    <div className='w-full px-6 md:w-[80%] mx-auto font-[poppins]'>
                        <div className='flex flex-col md:flex-row items-center gap-y-6'>

                            <div className='w-full md:w-[50%] mx-auto flex justify-center md:justify-start ' data-aos='fade-right'>
                                <img src={live} className=' w-full md:w-[80%] rounded-lg shadow-md drop-shadow-lg object-cover' alt="" />
                            </div>

                            <div className='w-full md:w-[50%] mx-auto' data-aos='fade-left'>

                                <div>
                                    <h3 className='font-bold uppercase text-[18px] md:text-[24px] mb-2 md:mb-4'> <span className='text-orange-500'>Our</span> Approach</h3>
                                    <p className='text-[12px] md:text-[14px] font-[roboto] leading-6'>We take a holistic approach to addressing the interconnected challenges faced by underserved communities.
                                        Through educational empowerment, livelihood development, poverty alleviation initiatives, and community resilience programs,
                                        we strive to create lasting and sustainable change.
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                {/* --------- Education --------  */}

                <section className='bg-white pt-10 md:pt-20 pb-10 md:pb-24'>
                    <div className='w-full px-6 md:w-[80%] mx-auto font-[poppins]'>
                        <div className='flex flex-col md:flex-row-reverse items-center gap-y-6'>

                            <div className='w-full md:w-[50%] mx-auto flex justify-center md:justify-end ' data-aos='fade-left'>
                                <img src={education} className='w-full md:w-[80%] rounded-lg shadow-md drop-shadow-lg object-cover' alt="" />
                            </div>

                            <div className='w-full md:w-[50%] mx-auto' data-aos='fade-right'>

                                <div>
                                    <h3 className='font-bold uppercase text-[18px] md:text-[24px] mb-2 md:mb-4'> <span className='text-orange-500'>Education :</span> The Key to Transformation</h3>
                                    <p className='text-[12px] md:text-[14px] font-[roboto] leading-6'>We take a holistic approach to addressing the interconnected challenges faced by underserved communities.
                                        Through educational empowerment, livelihood development, poverty alleviation initiatives, and community resilience programs,
                                        we strive to create lasting and sustainable change.
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>


                {/* --------- Sustainable Livelihoods --------  */}

                <section className='pt-6 md:pt-20 pb-10 md:pb-24'>
                    <div className='w-full px-6 md:w-[80%] mx-auto font-[poppins]'>
                        <div className='flex flex-col md:flex-row justify-center items-center'>



                            <div className=' '>

                                <div>
                                    <h3 className='text-center font-bold uppercase text-[16px] md:text-[32px] mb-2 md:mb-4 text-white' data-aos='fade-up' data-aos-duration='1000' data-aos-delay='30'> <span className='text-orange-500'>Sustainable Livelihoods : </span> Fostering Economic Empowerment</h3>
                                    <p className='text-center text-[12px] md:text-[18px] font-[roboto] leading-6 text-gray-100' data-aos='fade-up' data-aos-duration='2000' data-aos-delay='50'>
                                        Alongside education, we promote sustainable livelihoods by implementing skill development programs, entrepreneurship training, and income-generating opportunities.
                                        By empowering individuals with the tools and knowledge they need, we aim to foster economic self-sufficiency and break the cycle of poverty.
                                    </p>
                                </div>

                                <div className='flex justify-center mt-6 md:mt-10'>
                                    <div className='grid grid-cols-2 md:grid-cols-4 gap-x-28 gap-y-8 md:gap-x-20'>

                                        <div data-aos='fade-up' data-aos-duration='1000' data-aos-delay='30'>
                                            <img src={r1} className='rounded-full h-20 w-20  md:h-40 md:w-40 object-cover drop-shadow-sm shadow-md shadow-black/35' alt="" />
                                        </div>
                                        <div data-aos='fade-up' data-aos-duration='2000' data-aos-delay='30'>
                                            <img src={r2} className='rounded-full h-20 w-20  md:h-40 md:w-40 object-cover drop-shadow-sm shadow-md shadow-black/35' alt="" />
                                        </div>
                                        <div data-aos='fade-up' data-aos-duration='3000' data-aos-delay='30'>
                                            <img src={r3} className='rounded-full h-20 w-20  md:h-40 md:w-40 object-cover drop-shadow-sm shadow-md shadow-black/35' alt="" />
                                        </div>
                                        <div data-aos='fade-up' data-aos-duration='3000' data-aos-delay='60'>
                                            <img src={r4} className='rounded-full h-20 w-20  md:h-40 md:w-40 object-cover drop-shadow-sm shadow-md shadow-black/35' alt="" />
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>



                {/* --------- Community Resilience --------  */}


                <section className='bg-white pt-8 md:pt-20 pb-10 md:pb-24'>
                    <div className='w-full px-6 md:w-[80%] mx-auto font-[poppins]'>
                        <div className='flex flex-col md:flex-row-reverse items-center gap-y-6'>

                            <div className='w-full md:w-[50%] mx-auto flex justify-center md:justify-end' data-aos='fade-left'>
                                <img src={community} className='w-full md:w-[80%] rounded-lg shadow-md drop-shadow-lg object-cover' alt="" />
                            </div>
                            <div className='w-full md:w-[50%] mx-auto' data-aos='fade-right'>
                                <div>
                                    <h3 className='font-bold uppercase text-[18px] md:text-[24px] mb-2 md:mb-4'>
                                        <span className='typing-animation text-orange-500'>Community Resilience :</span> Building Strength from Within
                                    </h3>
                                    <p className='text-[12px] md:text-[14px] font-[roboto] leading-6'>
                                        We work closely with local communities, engaging them in decision-making processes and empowering them to become self-reliant.
                                        Our initiatives focus on promoting environmental sustainability, community development, and youth empowerment, fostering a sense of ownership and collective responsibility.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>




                {/* --------- Join Our Team --------  */}

                <section className='bg-white pt-8 md:pt-20 pb-10 md:pb-24'>
                    <div className='w-full px-6 md:w-[80%] mx-auto font-[poppins]'>
                        <div className='flex flex-col md:flex-row items-center gap-y-6'>

                            <div className='w-full md:w-[50%] mx-auto flex justify-center md:justify-start ' data-aos='fade-right'>
                                <img src={join} className='w-full md:w-[80%] rounded-lg shadow-md drop-shadow-lg object-cover' alt="" />
                            </div>

                            <div className='w-full md:w-[50%] mx-auto' data-aos='fade-left'>

                                <div>

                                    <h3 className='font-bold uppercase text-[18px] md:text-[24px] mb-2 md:mb-4'><span className='text-orange-500'> Join </span>  Our Movement</h3>
                                    <p className='text-[12px] md:text-[14px] font-[roboto] leading-6'>
                                        We invite you to join us in our mission to create a world where every community thrives with resilience and opportunity.
                                        Your support, whether through volunteering, donating, or partnering with us, can make a tangible difference in the lives of those we serve.
                                    </p>

                                    <div>
                                        <Link to='contact' onClick={toppage}>
                                            <button className='bg-[#3364e5] hover:scale-105 duration-300 shadow-md drop-shadow-lg px-5 py-1.5  font-semibold mt-5 rounded-lg text-white text-[14px]' >Join Now</button>
                                        </Link>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </section >

            </div >

        </>
    )
}

export default Home