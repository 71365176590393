import React from 'react'

import logo from '../images/Kenmai_logo2.png'

//icons
import { MdOutlinePhoneAndroid } from "react-icons/md";
import { HiOutlineMail } from "react-icons/hi";
import { Link } from 'react-router-dom';




const Footer = () => {

    function topPage() {
        window.scroll(0, 0)
    }

    return (
        <>
            <div className='font-[poppins]'>
                <div class="bg-[#0d003b]">
                    <div class="w-full px-4 md:w-[80%] pt-4  sm:px-6 text-gray-800 sm:grid md:grid-cols-4 sm:grid-cols-2 mx-auto">
                        <div class="p-5">
                            <img src={logo} className='object-cover w-20 md:w-32' alt="" />
                        </div>


                        <div class="p-5">
                            <div class="text-sm uppercase text-white font-bold">Join Us</div>
                            <Link onClick={topPage} to='/our-programs' class="my-3 block text-gray-400 hover:text-white text-[14px]" >Our Programs  </Link>
                            <Link onClick={topPage} to='/about' class="my-3 block text-gray-400 hover:text-white text-[14px]" >Mission Vision </Link>
                            <Link onClick={topPage} to='/partner-with-us' class="my-3 block text-gray-400 hover:text-white text-[14px]" >Partner </Link>
                            <Link onClick={topPage} to='/volunteer-with-us' class="my-3 block text-gray-400 hover:text-white text-[14px]" >Volunteer </Link>
                        </div>



                        <div class="p-5">
                            <div class="text-sm uppercase text-white font-bold">Contact us</div>
                            <p class="my-3 block text-gray-400 text-[14px]" href="/#"> Kenmai Foundation , No.18, Jai Garden 2nd Street Valasaravakkam,Chennai-600087 </p>
                            <p class="my-3 block text-gray-400 text-[14px]  " href="/#"><div className='flex items-center gap-x-3'> <span><MdOutlinePhoneAndroid className='text-gray-400 text-[16px]' /></span> +91 7418616456 </div> </p>
                            <p class="my-3 block text-gray-400 text-[14px]  " href="/#"><div className='flex items-center gap-x-3'><span><HiOutlineMail className='text-gray-400 text-[16px]' /></span> support@kenmai.org</div> </p>
                            <Link onClick={topPage} to='/terms-conditions' class="my-3 block text-gray-400 hover:text-white text-[14px]" > Terms & Conditions</Link>
                            <Link onClick={topPage} to='/refund-policy' class="my-3 block text-gray-400 hover:text-white text-[14px]" >Refund Policy</Link>
                            <Link onClick={topPage} to='/privacy-policy' class="my-3 block text-gray-400 hover:text-white text-[14px]" >Privacy Policy  </Link>

                        </div>

                        <div class="p-5 ">

                            <div className='bg-white drop-shadow-md shadow-md shadow-white/30 px-2 py-8 flex flex-col justify-center items-center rounded-2xl'>

                                <h3 className='text-center text-[14px] md:text-[18px] font-semibold'>Join Us in Making a Difference Today</h3>
                                <div className='mt-6'>
                                    <Link to='/donate' onClick={topPage}>
                                        <button className='bg-[#3263e4] hover:scale-105 duration-500 hover:bg-indigo-700 font-semibold px-3 py-2 rounded-lg text-[14px] text-white'>Donate Now</button>
                                    </Link>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>

                <div class="bg-[#0d003b] pt-2">
                    <div class="flex pb-5 px-3 m-auto pt-5 border-t text-gray-800 text-sm flex-col max-w-screen-lg items-center">

                        <div class="my-3 text-gray-500 font-[poppins] text-[14px]">©  Kenmai Foundation 2024. All Rights Reserved.</div>
                    </div>
                </div>


            </div>

        </>
    )
}

export default Footer