// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Header from './components/Header';
import Footer from './components/Footer';
import About from './pages/About';
import Programs from './pages/Programs';
import Contact from './pages/Contact';
import Volunteer from './pages/Volunteer';
import Donate from './pages/Donate';
import Partner from './pages/Partner';
import Privacy from './pages/Privacy';
import Refund from './pages/Refund';
import Terms from './pages/Terms';

const App = () => {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/our-programs" element={<Programs />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/volunteer-with-us" element={<Volunteer />} />
        <Route path="/donate" element={<Donate />} />
        <Route path="/partner-with-us" element={<Partner />} />
        <Route path="/terms-conditions" element={<Terms />} />
        <Route path="/refund-policy" element={<Refund />} />
        <Route path="/privacy-policy" element={<Privacy />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
