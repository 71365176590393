import React, { useState } from 'react';

const Razorpay = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [amount, setAmount] = useState('');

    const clearForm = () => {
        setName('');
        setEmail('');
        setPhone('');
        setAmount('');
    };

    const handlePayment = () => {
        // Custom validation logic
        if (!name || !email || !phone || !amount) {
            alert('Please fill out all required fields.');
            return;
        }

        const options = {
            key: 'rzp_live_NubptvmX6oJO12', 
            // Replace with your actual Razorpay Key ID
            amount: amount * 100, // Amount in paise
            currency: 'INR',
            name: name,
            description: '',
            handler: function (response) {
                alert(`Payment ID: ${response.razorpay_payment_id}`);
                // Clear the form after successful payment
                clearForm();
            },
            prefill: {
                name: name,
                email: email,
                contact: phone,
            },
            notes: {
                address: 'Some Address',
            },
            theme: {
                color: '#3399cc',
            },
        };

        const rzp = new window.Razorpay(options);
        rzp.open();
    };

    return (
        <div className="max-w-md mx-auto py-8 px-5 bg-white rounded shadow-md">
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                    Name
                </label>
                <input
                    id="name"
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
            </div>
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                    Email
                </label>
                <input
                    id="email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
            </div>
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="phone">
                    Phone
                </label>
                <input
                    id="phone"
                    type="tel"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
            </div>
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="amount">
                    Amount
                </label>
                <input
                    id="amount"
                    type="number"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
            </div>
            <div className='flex justify-center items-center mt-8 font-[poppins]'>
                <button
                    onClick={handlePayment}
                    className="bg-[#3263e4] hover:bg-blue-700 text-white font-semibold py-2 px-4 text-[14px] rounded focus:outline-none focus:shadow-outline"
                >
                    Donate Now
                </button>
            </div>
        </div>
    );
};

export default Razorpay;
