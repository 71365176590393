import React, { useEffect } from 'react'

const Refund = () => {




    return (
        <>

            <section className='bg-gray-50 flex  px-3 py-3 md:p-8 font-[roboto]' >
                <div className='w-full  md:w-[85%] bg-white p-4 rounded-md border-2 border-gray-100 drop-shadow-md shadow-lg mx-auto'>

                    <div className='my-3'>
                        <h3 className='text-gray-900 font-bold uppercase text-center font-[poppins] text-[14px] sm:text-[16px] md:text-[22px]'>refund policy</h3>
                    </div>

                    <div className='mt-8 flex flex-col  gap-y-4 md:gap-y-6 px-1 md:px-5'>

                        <div className='flex flex-col gap-y-3 '>
                            {/* <p className='font-bold text-[14px] sm:text-[16px] md:text-[20px] text-gray-600'>Introduction</p> */}
                            <p className='text-gray-600 text-[11px] sm:text-[12px] md:text-[14px] '>Kenmai Foundation is instituting a donation refund policy to ensure fair and transparent processing of requests for refund of donations as digital payments are becoming more frequent. Kenmai Foundation expects that all donors will exercise due care and diligence while making donations. Kenmai Foundation also recognises that a donation may be made erroneously or donors may change their mind.
                            </p>
                        </div>

                        <div className='flex flex-col gap-y-3 '>
                            {/* <p className='font-bold text-[14px] sm:text-[16px] md:text-[20px] text-gray-600'>1. Information We Collect</p> */}
                            <p className='text-gray-600 text-[11px] sm:text-[12px] md:text-[14px] '>Kenmai Foundation will examine each request for refund of donation and endeavour to make the refund. Kenmai Foundation may also seek further information / documents and donor must co-operate in this regard.                            </p>
                        </div>


                        <div className='flex flex-col gap-y-3 '>
                            {/* <p className='font-bold text-[14px] sm:text-[16px] md:text-[20px] text-gray-600'>2. How We Use Your Information</p> */}
                            <p className='text-gray-600 text-[11px] sm:text-[12px] md:text-[14px] '>However, Kenmai Foundation is not obliged to make refunds and may, in its discretion, decline any requests for refund of donations, particularly if a tax exemption certificate has been issued.                            </p>
                        </div>

                        <div className='flex flex-col gap-y-3 '>
                            {/* <p className='font-bold text-[14px] sm:text-[16px] md:text-[20px] text-gray-600'>3. Sharing Your Information</p> */}
                            <p className='text-gray-600 text-[11px] sm:text-[12px] md:text-[14px] '>If you would like your donation to Kenmai Foundation to be refunded, You must request Kenmai Foundation in writing or by email for a refund and Your request must reach Kenmai Foundation within 15 (fifteen) days from the date on which you made the donation i.e. the date on which you: </p>
                            <p className='text-gray-600 text-[11px] sm:text-[12px] md:text-[14px] '>Made the donation online, electronically or through other means, OR Handed over the cheque / demand draft to Kenmai Foundation or someone authorised by Kenmai Foundation for this purpose, OR
                                Despatched the cheque / demand draft to Kenmai Foundation by other means.
                                The written request stating reason for requesting refund must be sent to the address stated below and must contain all the following details pertaining to the donation: Date of donation
                            </p>
                        </div>


                        <div className='flex flex-col gap-y-3 '>
                            {/* <p className='font-bold text-[14px] sm:text-[16px] md:text-[20px] text-gray-600'>4. Data Security</p> */}
                            <p className='text-gray-600 text-[11px] sm:text-[12px] md:text-[14px] '>Donation amount
                                If donation was made through cheque/draft, please provide Cheque/Draft no.
                                If donation was made through credit card, please provide Credit Card no. (last 4 digits only).
                            </p>
                        </div>

                        <div className='flex flex-col gap-y-3 '>
                            <p className='font-bold text-[14px] sm:text-[16px] md:text-[20px] text-gray-600'>If donation was made online, please provide Form no:.</p>
                            <p className='text-gray-600 text-[11px] sm:text-[12px] md:text-[14px] '>Kenmai Foundation,18, Jain Garden, 2nd Street,</p>
                            <p className='text-gray-600 text-[11px] sm:text-[12px] md:text-[14px] '>ValasaravakkamChennai-600087</p>
                            <p className='text-gray-600 text-[11px] sm:text-[12px] md:text-[14px] '>Email: support@kenmai.org                            </p>


                        </div>


                        <div className='flex flex-col gap-y-3 '>
                            {/* <p className='font-bold text-[14px] sm:text-[16px] md:text-[20px] text-gray-600'>6. Third-Party Links</p> */}
                            <p className='text-gray-600 text-[11px] sm:text-[12px] md:text-[14px] '>All decisions of Kenmai Foundation in this regard will be final and binding on the donor. </p>

                        </div>

                    </div>
                </div>
            </section>

        </>
    )
}

export default Refund