import React, { useEffect } from 'react'
import Slider from "react-slick";
import './Home.css'
import AOS from 'aos'
import 'aos/dist/aos.css'



import education from '../images/banner/banner11.jpg'

import img1 from '../images/kenmai/img1.jpg'
import img2 from '../images/kenmai/img2.jpg'
import img4 from '../images/kenmai/img4.jpg'
import img5 from '../images/kenmai/img5.jpg'
import img6 from '../images/kenmai/img6.jpg'
import img7 from '../images/kenmai/img7.jpg'
import img8 from '../images/kenmai/img8.jpg'
import img9 from '../images/kenmai/img9.jpg'
import img10 from '../images/kenmai/img10.jpg'

import medical1 from '../images/health/img1.png'
import medical2 from '../images/health/img2.png'
import medical3 from '../images/health/img3.png'
import medical4 from '../images/health/img4.png'
import medical5 from '../images/health/img5.png'

import caseOne from '../images/case-1.jpg'
import caseTwo from '../images/case-1-report.jpg'



//icons
import { TbBuildingCommunity } from "react-icons/tb";
import { FaStreetView } from "react-icons/fa";
import { MdDeveloperMode } from "react-icons/md";
import { FaHandsHoldingChild } from "react-icons/fa6";

import { Link } from 'react-router-dom';

const Programs = () => {

    useEffect(() => {
        AOS.init({ duration: 1000 })
    }, [])

    function topPage() {
        window.scroll(0, 0)
    }


    const text = "OUR SERVICES";

    const settings = {
        className: "center",
        infinite: true,
        autoplay: true,
        slidesToScroll: 1,
        centerPadding: "60px",
        slidesToShow: 3,
        speed: 4000,
        autoplaySpeed: 2000,
        cssEase: "linear",
        swipeToSlide: true,
        afterChange: function (index) {
            console.log(`Slider Changed to: ${index + 1}, background: #222; color: #bada55`);
        },
        responsive: [
            {
                breakpoint: 2500,
                settings: {
                    slidesToShow: 4,
                    centerPadding: "30px",
                }
            },
            {
                breakpoint: 2000,
                settings: {
                    slidesToShow: 3,
                    centerPadding: "30px",
                }
            },
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 3,
                    centerPadding: "30px",
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    centerPadding: "30px",
                }
            },

            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    centerPadding: "20px",
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    centerPadding: "15px",
                }
            }
        ]
    };


    const settingsOne = {
        className: "center",
        infinite: true,
        autoplay: true,
        slidesToScroll: 1,
        centerPadding: "60px",
        slidesToShow: 3,
        speed: 4000,
        autoplaySpeed: 2000,
        cssEase: "linear",
        swipeToSlide: true,
        afterChange: function (index) {
            console.log(`Slider Changed to: ${index + 1}, background: #222; color: #bada55`);
        },
        responsive: [
            {
                breakpoint: 2500,
                settings: {
                    slidesToShow: 4,
                    centerPadding: "30px",
                }
            },
            {
                breakpoint: 2000,
                settings: {
                    slidesToShow: 3,
                    centerPadding: "30px",
                }
            },
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 3,
                    centerPadding: "30px",
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    centerPadding: "30px",
                }
            },

            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    centerPadding: "20px",
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    centerPadding: "15px",
                }
            }
        ]
    };

    return (
        <>
            <div className='overflow-hidden'>

                <section>

                    <div className='relative font-[poppins]'>

                        <div>
                            <img src={education} className='w-full' alt="" />
                        </div>

                        <div className='absolute top-8 left-3 md:top-52  md:left-20 w-full'>

                            <h3 className='font-bold text-[12px] md:text-[42px] uppercase'>Empowering Through Education</h3>

                            <p className='w-[60%] md:w-[50%]  mt-1 md:mt-4 font-[roboto] text-[9px] md:text-[16px] md:leading-7 text-gray-700'>Kenmai Foundation transforms lives through education by establishing schools, providing scholarships, and enhancing education quality with teacher training and curriculum development. We also boost employability with vocational and computer literacy programs.</p>

                        </div>
                    </div>
                </section>

                <section className=' pt-8 md:pt-20 pb-8 md:pb-20 bg-[#edeae1] font-[poppins]'>
                    <div className='w-full px-4 md:w-[80%] mx-auto flex justify-center'>

                        <div className='grid grid-cols-1 md:grid-cols-2 gap-x-20 gap-y-16'>

                            <div className='flex flex-col items-center justify-center gap-y-3 bg-white shadow-md drop-shadow-md shadow-gray-300 rounded-md px-4 md:px-5 py-4 md:py-6' data-aos='fade-right'>
                                <div>
                                    <TbBuildingCommunity className='text-[40px] md:text-[60px] text-[#3364e5]' />
                                </div>

                                <div>

                                    <h3 className='font-bold text-[16px] md:text-[20px] text-center uppercase'>Community Resilience</h3>

                                    <p className='mt-1 md:mt-3 font-normal text-[12px] md:text-[14px] text-justify text-gray-700'>
                                        Kenmai Foundation is dedicated to fostering a sustainable and inclusive world. We promote eco-friendly practices, effective waste management, and environmental conservation.
                                        Our community development programs enhance cohesion, inclusivity, and self-reliance. Additionally, we empower youth through leadership development, skill-building, and active
                                        engagement as catalysts for positive change.
                                    </p>

                                </div>
                            </div>


                            <div className='flex flex-col items-center justify-center gap-y-3 bg-white shadow-md drop-shadow-md shadow-gray-300 rounded-md px-4 md:px-5 py-4 md:py-6' data-aos='fade-left' >
                                <div>
                                    <FaStreetView className='text-[40px] md:text-[60px]  text-[#3364e5]' />
                                </div>

                                <div>

                                    <h3 className='font-bold text-[16px] md:text-[20px]  text-center uppercase'>Livelihood</h3>

                                    <p className='mt-1 md:mt-3 font-normal text-[12px] md:text-[14px] text-justify text-gray-700'>
                                        Kenmai Foundation: Empowering through Entrepreneurship, Skills, and Women's Initiatives
                                        We provide training, microfinance access, and business incubation for entrepreneurs. Our vocational programs in tailoring, carpentry, and agriculture boost employment.
                                        Women benefit from skill development, financial literacy, and income-generating activities, fostering empowerment.
                                    </p>

                                </div>
                            </div>


                            <div className='flex flex-col items-center justify-center gap-y-3 bg-white shadow-md drop-shadow-md shadow-gray-300 rounded-md px-4 md:px-5 py-4 md:py-6' data-aos='fade-right' >
                                <div>
                                    <MdDeveloperMode className='text-[40px] md:text-[60px]  text-[#3364e5]' />
                                </div>

                                <div>

                                    <h3 className='font-bold text-[16px] md:text-[20px]  text-center uppercase'>Skill Development</h3>

                                    <p className='mt-1 md:mt-3 font-normal text-[12px] md:text-[14px] text-justify text-gray-700'>
                                        Skill education empowers individuals to turn ideas into reality, making a positive impact on communities.
                                        It strengthens social connections and promotes collaboration. Join us in conducting workshops, training sessions, and vocational courses for youth and adults.
                                        Together, we empower individuals with the skills for sustainable employment, breaking the cycle of poverty.
                                    </p>

                                </div>
                            </div>


                            <div className='flex flex-col items-center justify-center gap-y-3 bg-white shadow-md drop-shadow-md shadow-gray-300 rounded-md px-4 md:px-5 py-4 md:py-6' data-aos='fade-left' >
                                <div>
                                    <FaHandsHoldingChild className='text-[40px] md:text-[60px]  text-[#3364e5]' />
                                </div>

                                <div>

                                    <h3 className='font-bold text-[16px] md:text-[20px]  text-center uppercase'>Poverty Alleviation</h3>

                                    <p className='mt-1 md:mt-3 font-normal text-[12px] md:text-[14px] text-justify text-gray-700'>
                                        We distribute ration kits and support community kitchens to tackle hunger.
                                        Our health initiatives include awareness campaigns, clean water access, and basic healthcare services.
                                        During crises, we provide emergency aid, shelter, and rehabilitation support.
                                        Join us in ensuring food security, promoting health, and offering disaster relief to vulnerable communities.
                                    </p>

                                </div>
                            </div>


                        </div>

                    </div>
                </section>


                {/* ---------------------- healthcare ----------------------- */}

                <section className='mt-8 md:mt-20 mb-4 md:mb-20'>
                    <div className=' w-full px-4 md:w-[80%] mx-auto font-[poppins]'>

                        <div className='flex flex-col justify-center items-center'>
                            <h3 className='font-semibold uppercase text-[14px] md:text-[24px] text-center'>Urgent Medical Assistance: Kenmai Foundation's Immediate Healthcare Support</h3>
                            <p className='text-[10px] md:text-[14px] text-gray-800 mt-1 md:mt-3 text-center'>Kenmai Foundation provides critical emergency healthcare services to those in need. Together, we can offer lifesaving care and support during times of crisis, ensuring that everyone receives the urgent medical attention they deserve.</p>
                        </div>

                        <div className='flex justify-center items-center w-full'>

                            {/* <div className="slider-container mt-12" data-aos='zoom-in'>
                                <Slider {...settingsOne}>

                                    <div className='px-2 '>
                                        <img src={medical1} className='object-cover rounded-md shadow-md drop-shadow-md shadow-black/20' alt="" />
                                    </div>

                                    <div className='px-2 '>
                                        <img src={medical2} className='object-cover rounded-md shadow-md drop-shadow-md shadow-black/20' alt="" />
                                    </div>

                                    <div className='px-2 '>
                                        <img src={medical3} className='object-cover rounded-md shadow-md drop-shadow-md shadow-black/20' alt="" />
                                    </div>

                                    <div className='px-2 '>
                                        <img src={medical4} className='object-cover rounded-md shadow-md drop-shadow-md shadow-black/20' alt="" />
                                    </div>

                                    <div className='px-2 '>
                                        <img src={medical5} className='object-cover rounded-md shadow-md drop-shadow-md shadow-black/20' alt="" />
                                    </div>

                                </Slider>
                            </div> */}

                            <div className='grid grid-cols-1 md:grid-cols-2 gap-y-20 gap-x-14 mt-10 px-3 md:px-0'>

                                <div className='relative flex flex-col items-center  justify-center'>

                                    <div className='flex justify-center'>
                                        <img src={caseOne} className=' w-full md:w-[65%] object-cover' alt="" />
                                    </div>


                                    <div className='absolute -bottom-12 md:-bottom-16'>
                                       

                                        <Link to='/donate' onClick={topPage}>
                                            <button className='shadow-lg drop-shadow-sm hover:scale-105 duration-500 shadow-gray-300 px-3 py-1.5 md:px-7 md:py-2.5 uppercase text-[12px] md:text-[14px] font-semibold bg-[#3263e4] rounded-md md:rounded-lg text-white hover:text-gray-800 border border-gray-100 hover:border hover:border-blue-600 hover:bg-white'>Donate now</button>
                                        </Link>
                                    </div>


                                </div>

                                <div className='flex justify-center'>
                                    <img src={caseTwo} className='w-full md:w-[65%] object-cover' alt="" />
                                </div>

                            </div>

                        </div>

                    </div>
                </section>

                {/* -------------------- our values ------------------------------- */}

                <section className='pt-10 md:pt-20 pb-10 md:pb-20 '>

                    <div className='flex justify-center items-center mb-8 font-[poppins] font-bold'>
                        <div className="w-max" data-aos='fade-up' data-aos-duration="1000">
                            {text.split('').map((char, index) => (
                                <span
                                    key={index}
                                    className="animate-wave text-center text-[20px] sm:text-[24px] md:text-[32px] font-bold text-gray-800 uppercase"
                                    style={{ animationDelay: `${index * 0.1}s` }}
                                >
                                    {char === ' ' ? '\u00A0' : char}
                                </span>
                            ))}
                        </div>
                    </div>

                    <div className='w-[80%] mx-auto'>

                        <div className="slider-container" data-aos='zoom-in'>
                            <Slider {...settings}>
                                <div className='px-2 '>
                                    <img src={img1} className='object-cover rounded-md shadow-md drop-shadow-md shadow-black/20' alt="" />
                                </div>
                                <div className='px-2 '>
                                    <img src={img2} className='object-cover rounded-md shadow-md drop-shadow-md shadow-black/20' alt="" />
                                </div>


                                <div className='px-2 '>
                                    <img src={img4} className='object-cover rounded-md shadow-md drop-shadow-md shadow-black/20' alt="" />
                                </div>


                                <div className='px-2 '>
                                    <img src={img5} className='object-cover rounded-md shadow-md drop-shadow-md shadow-black/20' alt="" />
                                </div>


                                <div className='px-2 '>
                                    <img src={img6} className='object-cover rounded-md shadow-md drop-shadow-md shadow-black/20' alt="" />
                                </div>


                                <div className='px-2 '>
                                    <img src={img7} className='object-cover rounded-md shadow-md drop-shadow-md shadow-black/20' alt="" />
                                </div>


                                <div className='px-2 '>
                                    <img src={img8} className='object-cover rounded-md shadow-md drop-shadow-md shadow-black/20' alt="" />
                                </div>


                                <div className='px-2 '>
                                    <img src={img9} className='object-cover rounded-md shadow-md drop-shadow-md shadow-black/20' alt="" />
                                </div>


                                <div className='px-2 '>
                                    <img src={img10} className='object-cover rounded-md shadow-md drop-shadow-md shadow-black/20' alt="" />
                                </div>


                            </Slider>
                        </div>


                    </div>


                </section>

            </div>
        </>
    )
}

export default Programs