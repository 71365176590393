import React , {useEffect} from 'react'

import AOS from 'aos'
import 'aos/dist/aos.css'

import { MdOutlinePhoneAndroid } from "react-icons/md";
import { MdOutlineMailOutline } from "react-icons/md";
import { GrLocation } from "react-icons/gr";


const Contact = () => {

    useEffect(() => {
        AOS.init({ duration: 1000 })
    }, [])
    
    return (
        <>

            <div className='overflow-hidden'>

                <section className=''>
                    <div class=" inset-0 -z-10 h-full w-full bg-white bg-[linear-gradient(to_right,#f0f0f0_1px,transparent_1px),linear-gradient(to_bottom,#f0f0f0_1px,transparent_1px)] bg-[size:6rem_4rem]">
                        <div class="px-4 md:px-0 pt-8 md:pt-14 pb-10 md:pb-20 bottom-0 left-0 right-0 top-0 bg-[radial-gradient(circle_500px_at_50%_200px,#C9EBFF,transparent)]">

                            <div className='flex flex-col  justify-center items-center font-[poppins]'>
                                <h2 class="text-[18px] md:text-[24px] font-bold mb-3 md:mb-6 text-center">Contact Us</h2>
                                <p className='text-center text-[12px] md:text-[14px] md:w-[70%] flex justify-center items-center'>
                                    Connect with Kenmai Foundation to learn, collaborate, or support our mission.
                                    Use the form below or contact us directly via email or phone.
                                    We welcome your interest and look forward to hearing from you!
                                </p>
                            </div>

                            <div className='w-full  md:w-[80%] mx-auto font-[poppins] flex flex-col md:flex-row justify-center items-start gap-x-20'>


                                <div className='w-full md:w-[50%]' data-aos='fade-left'>

                                    <form class="max-w-md mx-auto mt-8 md:mt-20 px-6 py-5 bg-white rounded-lg shadow-lg">

                                        <div class="mb-4">
                                            <label class="block text-gray-700 font-bold mb-2 text-[14px] md:text-[16px]" for="name">
                                                Name:
                                            </label>
                                            <input class="shadow appearance-none border rounded w-full text-[12px] md:text-[14px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="name" type="text" placeholder="Enter your name" />
                                        </div>
                                        <div class="mb-4">
                                            <label class="block text-gray-700 font-bold mb-2 text-[14px] md:text-[16px]" for="email">
                                                Email:
                                            </label>
                                            <input class="shadow appearance-none border rounded w-full text-[12px] md:text-[14px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="email" type="email" placeholder="Enter your email" />
                                        </div>
                                        <div class="mb-4">
                                            <label class="block text-gray-700 font-bold mb-2 text-[14px] md:text-[16px]" for="phone">
                                                Phone:
                                            </label>
                                            <input class="shadow appearance-none border rounded w-full text-[12px] md:text-[14px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="phone" type="tel" placeholder="Enter your Phone Number" />
                                        </div>
                                        <div class="mb-4">
                                            <label class="block text-gray-700 font-bold mb-2 text-[14px] md:text-[16px]" for="feedback">
                                                Message:
                                            </label>
                                            <textarea class="shadow appearance-none border rounded w-full text-[12px] md:text-[14px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="feedback" rows="5" placeholder="Enter your Message"></textarea>
                                        </div>
                                        <button class="text-[14px] md:text-[16px] bg-gray-900 w-full text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                                            Submit
                                        </button>
                                    </form>

                                </div>

                                <div className=' w-full md:w-[50%] mt-20 mx-auto' data-aos='fade-right'>

                                    <div className='flex flex-col gap-y-6'>

                                        <div className='flex items-center gap-x-2'>
                                            <div>
                                                <MdOutlinePhoneAndroid  className='text-[18px] md:text-[20px] text-gray-950' />
                                            </div>
                                            <p className='text-[12px] md:text-[14px] font-semibold'>+91 7418616456</p>
                                        </div>

                                        <div className='flex items-center gap-x-2'>
                                            <div>
                                                <MdOutlineMailOutline className='text-[18px] md:text-[20px]  text-gray-950' />
                                            </div>
                                            <p className='text-[12px] md:text-[14px] font-semibold'>support@kenmai.org   </p>
                                        </div>

                                        <div className='flex items-start gap-x-2'>
                                            <div>
                                                <GrLocation className='mt-1 text-[18px] md:text-[20px]  text-gray-950' />
                                            </div>
                                            <div>

                                                <p className='text-[12px] md:text-[14px] font-semibold'>Kenmai Foundation, No.18, Jai Garden 2nd Street </p>
                                                <p className='text-[12px] md:text-[14px] font-semibold'>Valasaravakkam, Chennai-600087</p>
                                            </div>
                                        </div>

                                        <div className='flex flex-col gap-y-4'>
                                            <div>
                                                <h3 className='font-semibold text-[14px] md:text-[16px] uppercase text-gray-900'>For General Inquiries:</h3>
                                                <p className='mt-2 text-[12px] md:text-[14px] font-[roboto] text-gray-600'>Whether you have questions about our programs, partnerships, or how to get involved, our team is here to assist you. Please feel free to contact us via email or phone during our office hours.</p>
                                            </div>

                                            <div>
                                                <h3 className='font-semibold text-[14px] md:text-[16px] uppercase text-gray-900'>For Corporate Partnerships and CSR Opportunities:</h3>
                                                <p className='mt-2 text-[12px] md:text-[14px] font-[roboto] text-gray-600'>If you represent a corporate organization interested in exploring partnership or corporate social responsibility (CSR) opportunities, please reach out to our dedicated team at cvsupport@kenmai.org.</p>
                                            </div>

                                            {/* <div>
                                                <h3 className='font-semibold text-[16px] uppercase text-gray-900'>For Media and Press Inquiries:</h3>
                                                <p className='mt-2 text-[14px] font-[roboto] text-gray-800'>Members of the media seeking information, interviews, or press materials can contact our communications team at support@kenmai.org.</p>
                                            </div> */}

                                            <div>
                                                <h3 className='font-semibold text-[14px] md:text-[16px] uppercase text-gray-900'>For Donations and Fundraising:</h3>
                                                <p className='mt-2 text-[12px] md:text-[14px] font-[roboto] text-gray-600'>To make a donation or inquire about fundraising opportunities, please visit our donation page or contact support@kenmai.org.</p>
                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </section>

            </div>

        </>
    )
}

export default Contact